// 导入全局的svg图标
import '@/plugins/svgIcon'

// 初始化多语言
import { setupI18n } from '@/plugins/vueI18n'

// 引入状态管理
import { setupStore } from '@/store'

// 全局组件
import { setupGlobCom } from '@/components'

// 引入element-plus
import { setupElementPlus } from '@/plugins/elementPlus'

//引入element-plus的图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

// 引入windi css
import '@/plugins/windi.css'

// 引入全局样式
import '@/styles/index.less'

// 引入动画
import '@/plugins/animate.css'

// 路由
import { setupRouter } from '@/router'

import { createApp } from 'vue'

import App from '@/App.vue'
import { initAuth } from './utils/auth'

import './permission'

const runApp = async () => {
  const app = createApp(App)
  
  initAuth()

  await setupI18n(app)

  setupStore(app)

  setupGlobCom(app)

  setupElementPlus(app)

  setupRouter(app)

  for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }


  app.mount('#app')
}

runApp()
