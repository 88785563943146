import { config } from '@/config/axios'
import { MockMethod } from 'vite-plugin-mock'

const { result_code } = config

const timeout = 1000

const adminList = [
  {
    path: '/business ',
    component: '##',
    redirect: '/business/presale',
    name: 'Business',
    meta: {
      title: '业务管理',
      icon: 'carbon:skill-level-advanced'
    },
    children: [
      {
        path: '/business/presale',
        name: 'Presale',
        meta: {
          title: ''
        }
      }
    ]
  }
]
const testList: string[] = ['/business/presale']

export default [
  // 列表接口
  {
    url: '/role/list',
    method: 'get',
    timeout,
    response: ({ query }) => {
      const { roleName } = query
      return {
        code: result_code,
        data: {
          list: roleName === 'admin' ? adminList : testList
        }
      }
    }
  }
] as MockMethod[]
